<template>
  <div class="campaign-setup-pixels-list-item campaign-setup-pixels-list-item-skeleton">
    <section class="campaign-setup-pixels-list-item__header">
      <div class="campaign-setup-pixels-list-item__header-left">
        <p class="campaign-setup-pixels-list-item__header-left-title">
          <skeleton-box height="16px" width="80%" style="margin: 0;" />
        </p>
      </div>
      <div class="campaign-setup-pixels-list-item__header-middle">
        <p class="campaign-setup-pixels-list-item__header-middle-title1">
          <skeleton-box height="12px" width="60%" />
        </p>
        <p class="campaign-setup-pixels-list-item__header-middle-title2">
          <skeleton-box height="12px" width="50%" />
        </p>
      </div>
    </section>
  </div>
</template>

<script>
import SkeletonBox from '@/components/skeletons/SkeletonBox';

export default {
  name: 'CampaignSetupPixelsListItemSkeleton',
  components: { SkeletonBox }
};
</script>

<style lang="scss" scoped>
.campaign-setup-pixels-list-item-skeleton {
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0 $--clb-space-3;
  border: 1px solid $--clb-border-color-base;
  border-radius: $--clb-border-radius;

  .campaign-setup-pixels-list-item__header-middle {
    margin-right: 60px;
    justify-content: center;

    p {
      height: 16px;
      display: flex;
    }
  }

  @media (min-width: $--sm) {
    .campaign-setup-pixels-list-item__header-middle {
      margin-right: 176px;

      .skeleton-box {
        height: 16px !important;
      }
    }
  }
}
</style>
