<template>
  <section class="campaign-setup-pixels-events">
    <label :for="`pixel-${pixel.pixel_id}-event`" class="campaign-setup-pixels-events__label">Fires At</label>
    <div :class="{ is_focus, 'inline-select--mini': !appendToBody }">
      <el-select
        :id="`pixel-${pixel.pixel_id}-event`"
        :key="appendToBody"
        v-model="pixel.event"
        v-select-close-on-blur
        :disabled="disabled || availableEvents.length <= 1"
        :popper-append-to-body="appendToBody"
        :popper-class="popperClass"
        filterable
        size="mini"
        placeholder="Select Event"
        no-match-text="No Event Found"
        class="campaign-setup-pixels-events__select"
        @visible-change="is_focus = !is_focus"
        @change="handleChangeEvent">
        <div class="campaign-setup-pixels-events__select-menu">
          <el-option-group
            label="Single Event">
            <el-option
              v-for="event in availableEvents"
              :key="`event-${event}`"
              :value="event"
              :label="event">
              {{ event }}
            </el-option>
          </el-option-group>
          <el-option-group
            v-if="availableEvents.length > 1"
            label="Multi Event">
            <el-option
              value="Event"
              label="Funnel Events" />
          </el-option-group>
        </div>
      </el-select>
    </div>
    <div v-if="!pixel.global && availableEvents.length > 1" class="campaign-setup-pixels-events__content">
      <app-node-strip v-scrollable:x :nodes="sortedEvents" :active-nodes="activeEvents" />
    </div>
  </section>
</template>

<script>
import AppNodeStrip from '@/components/AppNodeStrip';
import Breakpoints from '@/mixins/Breakpoints';
import union from 'lodash/union';

export default {
  name: 'CampaignSetupPixelsEvents',
  components: { AppNodeStrip },
  mixins: [Breakpoints([768])],
  props: {
    pixel: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      is_focus: false
    };
  },
  computed: {
    campaignId() {
      return this.$route.params.id;
    },
    campaign() {
      return this.$store.getters.campaign(this.campaignId);
    },
    appendToBody() {
      return this.windowBreakpoint >= 768;
    },
    popperClass() {
      return !this.appendToBody ? 'mw-550 inline' : '';
    },
    activeEvents() {
      return this.pixel.event === 'Event' ? this.availableEvents.filter((e) => e !== 'Purchase') : [this.pixel.event];
    },
    availableEvents() {
      return union(['Purchase', this.pixel.event], this.campaign ? this.campaign.events : []);
    },
    sortedEvents() {
      return union(this.campaign ? this.campaign.events : [], ['Purchase']);
    }
  },
  methods: {
    handleChangeEvent(e) {
      const clone = JSON.parse(JSON.stringify(this.pixel));
      clone.event = e;
      this.$emit('update-pixel', clone);
    }
  }
};
</script>

<style lang="scss">
.campaign-setup-pixels-events {
  .el-select-link {
    display: flex;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;
    border: 1px solid $--clb-border-color-base;
    border-radius: $--clb-border-radius;
    padding: 8px;
    outline: transparent;

    &:hover,
    &:focus {
      border-color: $--clb-color-primary;
      color: $--clb-color-primary;
    }
  }

  &__select {
    width: 100%;
  }

  &__content {
    overflow: auto;
    margin-top: $--clb-layout-2;
    padding: $--clb-space-2 0;
  }
}
</style>
