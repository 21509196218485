<template>
  <div class="campaign-setup-pixels-list">
    <template v-if="loading">
      <campaign-setup-pixels-list-item-skeleton v-for="index in 2" :key="index" />
    </template>
    <template v-else>
      <slot v-for="pixel in pixels" :pixel="pixel" />
      <slot name="footer" />
    </template>
  </div>
</template>

<script>
import CampaignSetupPixelsListItem from '@/components/setup/CampaignSetupPixelsListItem';
import CampaignSetupPixelsListItemSkeleton from '@/components/skeletons/setup/CampaignSetupPixelsListItemSkeleton';
import CampaignSetupPixelForm from '@/components/setup/CampaignSetupPixelForm';
import AppButton from '@/components/AppButton';

export default {
  name: 'CampaignSetupPixelsList',
  components: {
    AppButton,
    CampaignSetupPixelForm,
    CampaignSetupPixelsListItem,
    CampaignSetupPixelsListItemSkeleton
  },
  props: {
    pixels: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss">
.campaign-setup-pixels-list {
  .campaign-setup-pixels-list-item-skeleton {
    pointer-events: none !important;
  }
}
</style>
