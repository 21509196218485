import PixelCodeGenerator from '@/services/pixel/PixelCodeGenerator';

const EVENTS_MAP = {
  PageView: 'PageView',
  InitiateCheckout: 'InitiateCheckout',
  Purchase: 'Purchase'
};

class FacebookPixelCodeGenerator extends PixelCodeGenerator {
  getEvents() {
    return Object.keys(EVENTS_MAP);
  };

  generate(ids, events) {
    return `<script>
  !function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
${ids.map(id => `  fbq('init', '${id}');`).join('\n')}
${events.map(event => `  fbq('track', '${EVENTS_MAP[event]}');`).join('\n')}
</script>
`;
  };
}

export default new FacebookPixelCodeGenerator();
