<template>
  <div :class="{ 'campaign-setup-pixels-list-item--active' : showContent }" class="campaign-setup-pixels-list-item" @click.stop="!showContent && handleClickToggleContent()">
    <section class="campaign-setup-pixels-list-item__header">
      <transition name="fade" mode="out-in">
        <div v-if="!showContent" class="campaign-setup-pixels-list-item__header-left">
          <p class="campaign-setup-pixels-list-item__title">
            {{ pixel.name }}
          </p><span v-if="pixel.global" class="campaign-setup-pixels-list-item__header-badge"> All Campaigns </span>
        </div>
      </transition>
      <transition name="fade" mode="out-in">
        <div v-if="!showContent" class="campaign-setup-pixels-list-item__header-middle">
          <p class="campaign-setup-pixels-list-item__event">
            {{ pixel.event }}
          </p>
          <p class="campaign-setup-pixels-list-item__platform">
            {{ pixel.platform }}
          </p>
        </div>
      </transition>
      <div class="campaign-setup-pixels-list-item__header-right" @click.stop>
        <el-button-group>
          <template v-if="showContent">
            <el-button
              :loading="savingPixel"
              :disabled="!isFormValid"
              type="primary"
              class="next-button"
              icon="el-icon-check"
              @click.stop="handleClickSavePixel">
              Save
            </el-button>
            <el-button
              type="primary"
              class="next-button"
              icon="el-icon-close"
              @click.stop="handleCancelPixelEdit">
              Cancel
            </el-button>
          </template>
          <template v-else>
            <el-button
              type="primary"
              class="next-button"
              icon="el-icon-edit"
              @click.stop="handleClickToggleContent">
              Edit
            </el-button>
            <el-button
              :disabled="pixel.global"
              type="primary"
              class="next-button"
              icon="el-icon-delete"
              @click.stop="handleClickDeletePixel">
              Delete
            </el-button>
          </template>
        </el-button-group>
      </div>
    </section>
    <transition mode="out-in">
      <div
        v-if="showContent"
        ref="pixelFormSlot"
        class="campaign-setup-pixels-list-item__body">
        <slot />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'CampaignSetupPixelsListItem',
  props: {
    pixel: {
      type: Object,
      required: true
    },
    showContent: {
      type: Boolean,
      default: false
    },
    savingPixel: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isFormValid: false
    };
  },
  created() {
    this.$on('validate', valid => {
      this.isFormValid = valid;
    });
  },
  methods: {
    handleClickToggleContent() {
      this.$emit('toggle-content', this.pixel);
    },
    handleClickDeletePixel() {
      this.$emit('delete', this.pixel);
    },
    handleClickSavePixel() {
      this.$emit('save');
    },
    handleCancelPixelEdit() {
      this.$emit('cancel', this.pixel);
    }
  }
};
</script>

<style lang="scss">
.campaign-setup-pixels-list-item {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: $--clb-color-primary__white;
  border-radius: $--clb-border-radius;
  transition: $--all-transition, max-height 500ms ease-in-out, min-height 300ms ease-out;
  padding: $--clb-layout-1;
  cursor: pointer;

  &__header {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &-left {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex: 3 1 0;
      flex-direction: row;
      flex-wrap: wrap;
    }

    &-middle {
      display: flex;
      flex: 2 1 0;
      flex-direction: column;
      height: 36px;
    }

    &-right {
      display: flex;
      cursor: pointer;
    }

    &-badge {
      font-weight: normal;
      font-size: 10px;
      border-radius: $--clb-space-1;
      color: $--clb-body-font;
      white-space: nowrap;
      padding: $--clb-space-1 $--clb-space-1;
      background: #edf1ff;
      border: 1px solid $--clb-shadow-color;
      position: relative;
      top: -2px;

      .el-icon-success {
        font-size: $--clb-font-size-sm;
        color: #27d061;
        position: relative;
        top: 2px;
      }
    }
  }

  &__title {
    font-size: $--clb-font-size-sm !important;
    font-weight: 600;
    padding-right: $--clb-space-2;
  }

  &__event,
  &__platform {
    font-size: $--clb-font-size-xs !important;
    flex: 1 1 0;
    padding: 0 $--clb-space-1;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .el-button {
    &:first-child {
      margin-right: 0 !important;
      border-right: 1px solid rgba(255, 255, 255, 0.75);
    }

    &:hover {
      transform: translateY(0);
    }
  }

  &__body {
    transform: margin 500ms ease-in-out;
    margin-top: $--clb-space-4;

    label {
      display: inline-block;
      font-size: $--clb-font-size-base;
      font-weight: bold;
      margin-bottom: $--clb-space-2;
      color: $--clb-color__headings;
    }

    section {
      margin-bottom: $--clb-layout-2;
    }
  }

  &:active:not(&--active) {
    transform: translateY(0) !important;
  }

  &:hover:not(&--active) {
    box-shadow: $--box-shadow-light;
    transform: translateY(-1px);
    color: $--jb-light-primary-color;
    border-color: $--jb-light-primary-color;
  }

  &--active {
    min-height: 500px;
    max-height: 3000px;
    cursor: unset;
    flex-direction: column-reverse;

    &__body {
      margin-top: 0;
    }

    &:focus-within,
    &:hover {
      border-color: $--clb-color-primary__dark;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    }
  }
}

@media (min-width: $--sm) {
  .campaign-setup-pixels-list-item {
    &__header {
      &-left,
      &-middle {
        flex-direction: row;
        height: auto;
      }
    }

    &__title {
      font-size: $--clb-font-size-base !important;
    }

    &__event,
    &__platform {
      font-size: $--clb-font-size-sm !important;
    }
  }
}
</style>
