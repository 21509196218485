<template>
  <el-form
    :ref="'form'"
    :model="pixelData"
    class="campaign-setup-pixel-form"
    @submit.prevent="handlePixelFormSubmit">
    <div class="campaign-setup-pixel-form__body">
      <campaign-setup-pixels-general
        :disabled="isGlobalPixel"
        :pixel="pixelData"
        @update-pixel="handleUpdatePixel" />
      <campaign-setup-pixels-events :disabled="isGlobalPixel" :pixel="pixelData" @update-pixel="handleUpdatePixel" />
      <campaign-setup-pixels-event-filter :disabled="isGlobalPixel" :pixel="pixelData" @update-pixel="handleUpdatePixel" />
      <campaign-setup-pixels-platform :pixel="pixelData" @update-pixel="handleUpdatePixel" />
      <campaign-setup-pixels-domain v-if="isHtmlPixel" :pixel="pixelData" :organization-domains="organizationDomains" @update-pixel="handleUpdatePixel" />
      <campaign-setup-pixels-code :is-global-pixel="isGlobalPixel" :pixel="pixelData" @update-pixel="handleUpdatePixel" />
    </div>
  </el-form>
</template>

<script>
import CampaignSetupPixelsPlatform from '@/components/setup/CampaignSetupPixelsPlatform';
import CampaignSetupPixelsEvents from '@/components/setup/CampaignSetupPixelsEvents';
import CampaignSetupPixelsEventFilter from '@/components/setup/CampaignSetupPixelsEventFilter';
import CampaignSetupPixelsCode from '@/components/setup/CampaignSetupPixelsCode';
import CampaignSetupPixelsGeneral from '@/components/setup/CampaignSetupPixelsGeneral';
import CampaignSetupPixelsDomain from '@/components/setup/CampaignSetupPixelsDomain';

export default {
  name: 'CampaignSetupPixelForm',
  components: {
    CampaignSetupPixelsPlatform,
    CampaignSetupPixelsEventFilter,
    CampaignSetupPixelsEvents,
    CampaignSetupPixelsCode,
    CampaignSetupPixelsGeneral,
    CampaignSetupPixelsDomain
  },
  props: {
    pixel: {
      type: Object,
      required: true
    },
    organizationDomains: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      pixelData: JSON.parse(JSON.stringify(this.pixel))
    };
  },
  computed: {
    isGlobalPixel() {
      return this.pixel.global;
    },
    isHtmlPixel() {
      return this.pixelData.platform === 'HTML/JS';
    }
  },
  mounted() {
    this.$parent.$on('save', this.handlePixelFormSubmit);
    if (this.pixel.pixel_id !== null) {
      this.handleValidate();
    }
  },
  methods: {
    handleUpdatePixel(pixel) {
      this.pixelData = pixel;
      this.$nextTick(() => {
        this.handleValidate();
      });
    },
    handlePixelFormSubmit() {
      this.$emit('save', this.pixelData);
    },
    handleValidate() {
      this.$refs.form.validate(valid => {
        this.$parent.$emit('validate', valid);
      });
    }
  }
};
</script>

<style lang="scss">
.el-form-item.is-success:not(:hover) {
  .el-input__inner,
  .el-textarea__inner {
    &:not(:focus):not(:active) {
      border-color: $--clb-border-color-base;
    }
  }
}
</style>
