import PixelCodeGenerator from '@/services/pixel/PixelCodeGenerator';

const EVENTS_MAP = {
  PageView: 'page_view',
  Purchase: 'purchase'
};

class BingPixelCodeGenerator extends PixelCodeGenerator {
  getEvents() {
    return Object.keys(EVENTS_MAP);
  };

  generate(ids, events) {
    return `${ids.map(id => `<script>
  (function(w,d,t,r,u){var f,n,i;w[u]=w[u]||[] ,f=function(){var o={ti:"${id}"}; o.q=w
  [u],w[u]=new UET(o),w[u].push("pageLoad")} ,n=d.createElement(t),n.src=r,n.async=1,n.
  onload=n .onreadystatechange=function() {var s=this.readyState;s &&s!=="loaded"&& s!=
  ="complete"||(f(),n.onload=n. onreadystatechange=null)},i= d.getElementsByTagName(t)[
  0],i. parentNode.insertBefore(n,i)})(window,document,"script"," //bat.bing.com/bat.js
  ","uetq");
  window.uetq = window.uetq || [];<br />
${events.map(event => `  window.uetq.push('event', '${EVENTS_MAP[event]}', { page_path: '/spa_page' });`).join('\n')}
</script>`).join('\n')}`;
  };
}

export default new BingPixelCodeGenerator();
