<template>
  <section class="campaign-setup-pixels-general">
    <label :for="`pixel-${pixel.pixel_id}-name`" class="campaign-setup-pixels-general__label">Name</label>
    <el-form-item
      :rules="[{ required: true, message: 'You must name this pixel', trigger: ['change', 'blur'] }]"
      prop="name"
      required
      class="campaign-setup-pixels-general__item">
      <el-input
        :id="`pixel-${pixel.pixel_id}-name`"
        ref="input"
        v-scrollable:xy
        :value="pixel.name"
        :disabled="disabled"
        size="mini"
        required
        @input="handleInputChange" />
    </el-form-item>
  </section>
</template>

<script>
export default {
  name: 'CampaignSetupPixelsGeneral',
  props: {
    pixel: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    if (!this.$supportsTouch) {
      this.$refs.input.focus();
    }
  },
  methods: {
    handleInputChange(value) {
      const clone = JSON.parse(JSON.stringify(this.pixel));
      clone.name = value;
      this.$emit('update-pixel', clone);
    }
  }
};
</script>

<style lang="scss">
.campaign-setup-pixels-general {
  display: flex;
  flex-direction: column;
  flex: 1;

  &__heading {
    font-size: $--clb-h3__font-size !important;
    color: black;
    margin-bottom: 30px;
  }

  &__item {
    width: 100%;
    margin-bottom: 0;
  }

  &__input {
    display: flex;
  }

  .el-form-item__error {
    top: 40px;
    left: 0;
  }
}
</style>
