<template>
  <section class="campaign-setup-pixels-platform">
    <label :for="`pixel-${pixel.pixel_id}-platform`" class="campaign-setup-pixels-platform__label">Format</label>
    <div :class="{ is_focus, 'inline-select--mini': !appendToBody }" class="campaign-setup-pixels-platform__action-container">
      <el-select
        :id="`pixel-${pixel.pixel_id}-platform`"
        :key="appendToBody"
        v-model="pixel.platform"
        v-select-close-on-blur
        :popper-append-to-body="appendToBody"
        :popper-class="popperClass"
        filterable
        size="mini"
        placeholder="Select Platform"
        no-match-text="No Platform Found"
        class="campaign-setup-pixels-platform__select"
        @visible-change="is_focus = !is_focus"
        @change="handlePlatformChange">
        <div class="campaign-setup-pixels-platform__select-menu">
          <el-option
            v-for="platform in platforms"
            :key="`pixel-platform-${platform}`"
            :value="platform"
            class="campaign-setup-pixels-platform__select-item">
            {{ platform }}
          </el-option>
        </div>
      </el-select>
    </div>
  </section>
</template>

<script>
import Breakpoints from '@/mixins/Breakpoints';
import { EVENT_PLATFORMS, PLATFORMS } from '@/constants/pixels';
import union from 'lodash/union';

export default {
  name: 'CampaignSetupPixelsPlatform',
  mixins: [Breakpoints([768])],
  props: {
    pixel: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      is_focus: false
    };
  },
  computed: {
    appendToBody() {
      return this.windowBreakpoint >= 768;
    },
    popperClass() {
      return !this.appendToBody ? 'mw-550 inline' : '';
    },
    platforms() {
      return union(this.pixel.event === 'Purchase' ? PLATFORMS : EVENT_PLATFORMS, [this.pixel.platform]);
    }
  },
  watch: {
    platforms(newVal) {
      if (!newVal.includes(this.pixel.platform)) {
        this.handlePlatformChange(this.platforms[0]);
      }
    }
  },
  methods: {
    handlePlatformChange(platform) {
      const clone = JSON.parse(JSON.stringify(this.pixel));
      clone.platform = platform;
      this.$emit('update-pixel', clone);
    }
  }
};
</script>

<style lang="scss">
.campaign-setup-pixels-platform {
  &__action-container {
    display: block;
  }

  .el-dropdown-link {
    display: flex;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;
    border: 1px solid $--clb-border-color-base;
    border-radius: $--clb-border-radius;
    padding: 8px;
    outline: transparent;

    &:hover,
    &:focus {
      border-color: $--clb-color-primary;
      color: $--clb-color-primary;
    }
  }

  &__select {
    width: 100%;
  }
}
</style>
