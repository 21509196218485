import templates from './code-generators';

export default {
  /**
   * Checks is a given type has a generator
   * @param {string} type
   * @return {boolean}
   */
  hasGenerator(type) {
    return templates.hasOwnProperty(type);
  },
  /**
   * Cheks if a given type generator has all the available events
   * @param {string} type
   * @param {Array} events
   * @return {boolean}
   */
  hasEvents(type, events) {
    const generator = this.getGenerator(type);
    if (!generator) {
      return false;
    }
    for (const event of events) {
      if (!generator.hasEvent(event)) {
        return false;
      }
    }
    return true;
  },
  /**
   * Removes unsupported quotes from the given string
   * @param code
   */
  sanitizeCode(code) {
    return code
      .replace(/[\u2018\u2019]/g, "'")
      .replace(/[\u201C\u201D]/g, '"');
  },
  /**
   * Gets a generator instance of a given type
   * @param {string} type
   * @return {PixelCodeGenerator}
   */
  getGenerator(type) {
    return templates[type];
  },
  /**
   * Generates the code for a specific type with the given pixelsIds and events
   * @param {string} type
   * @param {Array} pixelIds
   * @param {Array} events
   * @return {*|string}
   */
  generate(type, pixelIds, events) {
    if (!this.hasGenerator(type)) {
      throw new Error(`No generator defined for type: ${type}`);
    }

    if (typeof pixelIds === 'string') {
      pixelIds = [pixelIds];
    }

    if (!this.hasEvents(type, events)) {
      throw new Error('Some of the events are not available for the generator');
    }

    return this.getGenerator(type).generate(pixelIds, events);
  }
};
