<template>
  <section class="campaign-setup-pixels-domain">
    <div class="campaign-setup-pixels-domain__label-wrapper">
      <el-popover
        placement="right"
        title="Pixel Domain"
        width="300"
        trigger="hover"
        popper-class="dark campaign-setup-pixels-domain__macros-popover">
        JS/HTML Pixels fire within an iFrame container. You are provided with a randomly assigned domain by default, which is shared by a small pool of users.
        <br><br>
        If you require the use of custom or unique domains, you may import your own, and we will manage the necessary pixels on your behalf.
        <br><br>
        Domains must be successfully verified before they can be used.
        <label slot="reference" :for="`pixel-${pixel.pixel_id}-domain`" class="campaign-setup-pixels-domain__label">
          Domain
          <i class="el-icon-info" />
        </label>
      </el-popover>
      <router-link class="campaign-setup-pixels-domain__manage-link" :to="{ name: 'settings-domains' }" target="_blank">
        Manage Domains
      </router-link>
    </div>
    <div v-loading="loadingDomains" :class="{ is_focus, 'inline-select--mini': !appendToBody }" class="campaign-setup-pixels-domain__action-container">
      <el-select
        :id="`pixel-${pixel.pixel_id}-domain`"
        :key="appendToBody"
        v-model="pixel.redirector_id"
        v-select-close-on-blur
        :popper-append-to-body="appendToBody"
        :popper-class="popperClass"
        filterable
        size="mini"
        placeholder="Select Domain"
        no-match-text="No Domain Found"
        class="campaign-setup-pixels-domain__select"
        @visible-change="is_focus = !is_focus"
        @change="handleDomainChange">
        <div class="campaign-setup-pixels-domain__select-menu">
          <el-option
            v-for="domain in availableDomains"
            :key="`pixel-domain-${domain.redirector_id}`"
            :value="domain.redirector_id"
            :label="domain.domain"
            class="campaign-setup-pixels-domain__select-item">
            <span>{{ domain.domain }}</span>
            <span class="campaign-setup-pixels-domain__select-item-tags">
              <span v-if="domain.type === 'affiliate'" class="tag-default">
                default
              </span>
              <span :class="[domain.status === 'active' ? 'tag-success' : 'tag-danger']">
                {{ domain.status }}
              </span>
            </span>
          </el-option>
        </div>
      </el-select>
    </div>
  </section>
</template>

<script>
import Breakpoints from '@/mixins/Breakpoints';

export default {
  name: 'CampaignSetupPixelsDomain',
  mixins: [Breakpoints([768])],
  props: {
    pixel: {
      type: Object,
      required: true
    },
    organizationDomains: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      is_focus: false
    };
  },
  computed: {
    loadingDomains() {
      return this.$store.getters.fetchingOrganizationDomains;
    },
    availableDomains() {
      return this.organizationDomains.filter(d => d.status !== 'unverified');
    },
    appendToBody() {
      return this.windowBreakpoint >= 768;
    },
    popperClass() {
      return !this.appendToBody ? 'mw-550 inline' : '';
    }
  },
  methods: {
    handleDomainChange(domain) {
      const clone = JSON.parse(JSON.stringify(this.pixel));
      clone.domain = domain;
      this.$emit('update-pixel', clone);
    }
  }
};
</script>

<style lang="scss">
  .campaign-setup-pixels-domain {
    &__action-container {
      display: block;
    }

    .el-dropdown-link {
      display: flex;
      justify-content: space-between;
      width: 100%;
      cursor: pointer;
      border: 1px solid $--clb-border-color-base;
      border-radius: $--clb-border-radius;
      padding: 8px;
      outline: transparent;

      &:hover,
      &:focus {
        border-color: $--clb-color-primary;
        color: $--clb-color-primary;
      }
    }

    &__select {
      width: 100%;
    }

    &__label-wrapper {
      display: flex;
      justify-content: space-between;
    }

    &__manage-link {
      font-size: $--clb-font-size-sm;
    }
  }

  .campaign-setup-pixels-domain__select-menu {
    .campaign-setup-pixels-domain__select-item {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .campaign-setup-pixels-domain__select-item-tags {
        span {
          margin-left: $--clb-space-2;
          font-size: 12px;
          padding: $--clb-space-1 $--clb-space-2;
          border-radius: $--clb-border-radius / 2;
          color: $--clb-color-primary__white;

          &.tag-default {
            background-color: $--clb-color-grey__grey;
          }

          &.tag-success {
            background-color: $--clb-color-green;
          }

          &.tag-danger {
            background-color: $--clb-color-red;
          }
        }
      }
    }
  }
</style>
