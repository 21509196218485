<template>
  <section class="campaign-setup-pixels-code">
    <el-collapse-transition mode="out-in">
      <CodeMirror
        v-if="!isPlatformPostback"
        :id="`pixel-${pixel.pixel_id}-code`"
        ref="codeEditor"
        v-scrollable:xy
        v-loading="initializing"
        :value="code"
        :options="editorOptions"
        class="campaign-setup-pixels-code__codemirror"
        @input="handleCodeInputChange"
        @ready="handleCodeEditorReady" />
      <el-form-item
        v-else
        :rules="[{ message: 'You must enter a valid URL', required: !isGlobalPixel, trigger: ['change', 'blur'], type: 'url' }]"
        prop="pixel">
        <el-input
          :id="`pixel-${pixel.pixel_id}-code`"
          ref="input"
          v-scrollable:xy
          :value="code"
          size="mini"
          @input="handleCodeInputChange" />
      </el-form-item>
    </el-collapse-transition>
    <div class="campaign-setup-pixels-code__macros">
      <el-popover
        placement="top-start"
        title="Passing Dynamic Data"
        width="300"
        trigger="hover"
        content="Macros enable you to send dynamic data back to your tracking pixels. Any macro used in your pixel will be replaced with its dynamic value."
        popper-class="dark campaign-setup-pixels-code__macros-popover">
        <label slot="reference"> Macros <i class="el-icon-info" />
        </label>
      </el-popover>
      <ul class="campaign-setup-pixels-code__macros-list">
        <li v-for="(macroName, macroKey) in macros" :key="macroKey" class="campaign-setup-pixels-code__macros-list-item">
          <a @click.prevent="handleClickMacro(macroKey)">{{ macroName }}</a>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import { MACROS } from '@/constants/pixels';
import CodeMirror from '@/components/AppCodeMirror';
import PixelCodeGeneratorService from '@/services/pixel';

export default {
  name: 'CampaignSetupPixelsCode',
  components: {
    CodeMirror
  },
  props: {
    pixel: {
      type: Object,
      required: true
    },
    isGlobalPixel: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      macros: MACROS,
      initializing: true,
      editorOptions: Object.freeze({
        mode: 'text/html',
        theme: 'jumbleberry-light',
        tabSize: 4,
        viewportMargin: 16,
        lineNumbers: true,
        line: true,
        lineWrapping: true
      }),
      pixelCodeGenerator: PixelCodeGeneratorService,
      otherPlatformCode: ''
    };
  },
  computed: {
    isPlatformPostback() {
      return this.pixel.platform === 'Postback';
    },
    label() {
      return this.isPlatformPostback ? 'URL' : 'Code';
    },
    code() {
      return this.pixel.pixel;
    },
    events() {
      return this.pixel.event ? [this.pixel.event] : [];
    },
    type() {
      return this.pixel.platform ? this.pixel.platform.toLowerCase() : '';
    }
  },
  watch: {
    isPlatformPostback(isPostback) {
      const existingCode = this.pixel.pixel;
      this.pixel.pixel = this.otherPlatformCode;
      this.handleCodeInputChange(this.pixel.pixel);
      this.otherPlatformCode = existingCode;
    }
  },
  methods: {
    handleCodeEditorReady() {
      this.initializing = false;
    },
    handleCodeInputChange(pixelCode) {
      const clone = JSON.parse(JSON.stringify(this.pixel));
      clone.pixel = PixelCodeGeneratorService.sanitizeCode(pixelCode);
      this.$emit('update-pixel', clone);
    },
    handleGenerateCode(code) {
      this.handleCodeInputChange(code);
      if (this.$refs.pixelCodeGenerator) {
        this.$refs.pixelGeneratorPopover.handleBlur();
      }
    },
    handleClickMacro(macro) {
      if (!this.isPlatformPostback) {
        return this.$refs.codeEditor.codemirror.replaceSelection(macro);
      }

      const input = this.$refs.input.$refs.input;
      const endPos = input.selectionEnd;
      this.pixel.pixel = input.value.slice(0, endPos) + macro + input.value.slice(endPos);
      this.$nextTick(() =>
        this.$nextTick(() => {
          this.setCaretPosition(input, endPos + macro.length);
        })
      );
    },
    setCaretPosition(element, position) {
      if (element.createTextRange) {
        var range = element.createTextRange();
        range.move('character', position);
        range.select();
      } else if (element.selectionStart) {
        element.focus();
        element.setSelectionRange(position, position);
      }
    }
  }
};
</script>

<style lang="scss">
.campaign-setup-pixels-code {
  margin-bottom: 0 !important;

  &__heading {
    display: flex;
    justify-content: space-between;
  }

  .el-form-item {
    margin-bottom: 0;
    transition: $--all-transition;

    &__error {
      position: relative;
      top: 0;
      left: 0;
    }
  }

  &__macros {
    margin-top: $--clb-space-2;
    display: flex;
    white-space: nowrap;

    label {
      margin: 0;
      line-height: 18px;
      font-size: $--clb-font-size-sm;
    }

    i {
      color: $--clb-color-accent__dark;
    }

    &-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;

      &-item {
        padding-left: $--clb-space-2;
        line-height: 18px;

        a {
          font-weight: 400;
          font-size: $--clb-font-size-xs;
          text-transform: uppercase;
          line-height: 14px;
          border-right: 1px solid $--clb-border-color-base;
          padding-right: $--clb-space-2;
        }

        &:last-child a {
          border-right: none;
          padding-right: 0;
        }
      }
    }
  }
}
</style>
