<template>
  <div class="campaign-setup-pixels">
    <header class="campaign-setup-pixels-header">
      <div class="campaign-setup-pixels-header__left">
        <p>Name</p>
      </div>
      <div class="campaign-setup-pixels-header__middle">
        <p class="hidden-xs">
          Fires At
        </p>
        <p class="hidden-xs">
          Type
        </p>
        <p class="hidden-sm-and-up">
          Fires At / Type
        </p>
      </div>
      <div class="campaign-setup-pixels-header__right">
        <p>Actions</p>
      </div>
    </header>
    <campaign-setup-pixels-list
      :pixels="pixels"
      :loading="fetchingPixels"
      class="campaign-setup-pixels__list">
      <campaign-setup-pixels-list-item
        :key="`pixel-${pixel.pixel_id}`"
        slot-scope="{ pixel }"
        :pixel="pixel"
        :show-content="showPixelContent(pixel)"
        :saving-pixel="savingPixel"
        @toggle-content="handleTogglePixelForm"
        @delete="handleDeletePixel"
        @cancel="handlePixelFormCancel">
        <campaign-setup-pixel-form
          :pixel="pixel"
          :organization-domains="organizationDomains"
          @save="handleSavePixel" />
      </campaign-setup-pixels-list-item>
      <template v-slot:footer>
        <div v-if="!disableAddNewPixels" class="campaign-setup-pixels__footer" @click="handleAddPixelButtonClick">
          <span class="tw-text-jb-ink tw-text-base tw-font-medium">Add Pixel</span>
          <el-button
            type="primary"
            class="next-button"
            icon="el-icon-plus">
            Add
          </el-button>
        </div>
      </template>
    </campaign-setup-pixels-list>
  </div>
</template>

<script>
import CampaignSetupPixelsListItem from '@/components/setup/CampaignSetupPixelsListItem';
import CampaignSetupPixelsList from '@/components/setup/CampaignSetupPixelsList';
import CampaignSetupPixelForm from '@/components/setup/CampaignSetupPixelForm';
import { PIXEL } from '@/constants/pixels';
import _debounce from 'lodash/debounce';

export default {
  name: 'CampaignSetupPixels',
  components: {
    CampaignSetupPixelsList,
    CampaignSetupPixelsListItem,
    CampaignSetupPixelForm
  },
  data() {
    return {
      fetchingPixels: false,
      savingPixel: false,
      pixels: [],
      visiblePixelForms: [],
      debouncedFetchDomains: _debounce(this.fetchDomains, 2000, { leading: true })
    };
  },
  computed: {
    campaignId() {
      return this.$route.params.id;
    },
    disableAddNewPixels() {
      return (
        this.pixels.find(p => {
          return p.pixel_id === null;
        }) !== undefined
      );
    },
    organizationPrimaryDomain() {
      return this.$store.getters.organizationPrimaryDomain;
    },
    organizationDomains() {
      return this.$store.getters.organizationDomains;
    }
  },
  watch: {
    campaignId() {
      this.getPixels();
    }
  },
  created() {
    this.getPixels();
  },
  destroyed() {
    window.removeEventListener('focus', this.debouncedFetchDomains);
  },
  methods: {
    async getPixels() {
      this.fetchingPixels = true;
      // Need to first get the organization domains,
      //   so we can properly set the correct domain for pixels with redirector_id of `null`
      await this.fetchDomains();
      this.$store
        .dispatch('fetchCampaignPixels', {
          orgId: this.$store.getters.organization.id,
          campaignId: this.$route.params.id
        })
        .then(pixels => {
          this.pixels = pixels;
        })
        .finally(() => {
          this.fetchingPixels = false;
        });
      window.addEventListener('focus', this.debouncedFetchDomains);
    },
    showPixelContent(pixel) {
      return this.visiblePixelForms.includes(pixel.pixel_id);
    },
    handleSavePixel(pixel) {
      this.savingPixel = true;
      this.$store
        .dispatch('savePixel', {
          orgId: this.$store.getters.organization.id,
          campaignId: this.$route.params.id,
          pixel
        })
        .then(response => {
          this.pixels = this.pixels.map(p =>
            pixel.pixel_id === p.pixel_id ? response : p
          );
          this.$message({
            dangerouslyUseHTMLString: true,
            message: `Pixel <strong>${response.name}</strong> Saved`,
            type: 'success'
          });
          this.handlePixelFormCancel(response);
        })
        .catch(e => {
          this.$message({
            message: 'There was an error saving the pixel.',
            type: 'error'
          });
        })
        .finally(() => {
          this.savingPixel = false;
        });
    },
    handleDeletePixel(pixel) {
      this.$confirm(
        'This will permanently delete the pixel. Continue?',
        'Warning',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }
      )
        .then(() => this.pixelDeleteConfirmation(pixel))
        .catch(() => {});
    },
    handleAddPixelButtonClick() {
      const newPixel = JSON.parse(JSON.stringify(PIXEL));
      newPixel.redirector_id = (this.organizationPrimaryDomain || { redirector_id: null }).redirector_id;
      this.pixels.push(newPixel);
      setTimeout(() => this.visiblePixelForms.push(newPixel.pixel_id), 16);
    },
    handleTogglePixelForm(pixel) {
      const index = this.visiblePixelForms.indexOf(pixel.pixel_id);
      if (index >= 0) {
        this.visiblePixelForms.splice(index, 1);
      } else {
        this.visiblePixelForms.push(pixel.pixel_id);
      }
    },
    handlePixelFormCancel(pixel) {
      const pixelIndex = this.visiblePixelForms.indexOf(pixel.pixel_id);
      if (pixelIndex >= 0) {
        this.visiblePixelForms.splice(pixelIndex, 1);
      }
      if (pixel.pixel_id === null) {
        this.pixels.splice(
          this.pixels.findIndex(p => p.pixel_id === pixel.pixel_id),
          1
        );
      }
    },
    pixelDeleteConfirmation(pixel) {
      this.$store
        .dispatch('deletePixel', {
          orgId: this.$store.getters.organization.id,
          campaignId: this.$route.params.id,
          pixel
        })
        .then(response => {
          this.handleTogglePixelForm(pixel);
          const index = this.pixels.findIndex(p => p.pixel_id === pixel.pixel_id);
          this.pixels.splice(index, 1);
          this.$message({
            message: 'Pixel Removed.',
            type: 'success'
          });
        })
        .catch(e => {
          this.$message({
            message: 'There was an error removing the pixel.',
            type: 'error'
          });
        });
    },
    fetchDomains() {
      return this.$api.organization.domains({
        storeAction: 'fetchOrganizationPixelDomains'
      });
    }
  }
};
</script>

<style lang="scss">
.campaign-setup-pixels {
  @include page-layout;

  @apply tw-pt-0;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;

  &-header {
    @apply tw-flex tw-transition tw-text-center tw-items-center tw-mx-space-3 tw-mb-space-4 sm:tw-mx-space-4;

    p {
      font-size: $--clb-font-size-xs;
      font-weight: 700;
      flex: 1 1 0;
    }

    &__left {
      flex: 3 1 0;
      text-align: left;
    }

    &__middle {
      flex: 2;
      display: flex;
    }

    &__right {
      width: 60px;
    }

    .campaign-setup-pixels__title {
      margin: 0;
    }
  }

  &-list-item {
    margin-bottom: $--clb-space-3;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $--clb-layout-1;
    border-radius: $--clb-border-radius;
    background: $--clb-skeleton-color;
    transition: $--all-transition;
    cursor: pointer;

    @apply tw-bg-jb-grey-50;

    &:hover {
      transform: translateY(-1px);
      box-shadow: $--box-shadow-dark;

      h6 {
        color: $--jb-light-primary-color;
      }
    }
  }

  .el-button {
    margin: 0;
    padding: 0;
    height: 36px;
    width: 36px;
    font-size: $--clb-font-size-xs;

    span {
      display: none;
    }

    i {
      font-size: $--clb-font-size-sm;
    }

    &:active,
    &:hover,
    &:focus {
      transform: translateY(0) !important;
    }
  }
}

@media (min-width: $--sm) {
  .campaign-setup-pixels {
    &-header {
      &__left {
        margin-right: -$--clb-space-4;
      }

      &__right {
        width: 176px;
      }
    }

    &__footer {
      h6 {
        font-size: $--clb-font-size-base;
      }
    }

    .el-button {
      width: 88px;
      height: 36px;

      span {
        display: inline;
      }
    }
  }
}
</style>
