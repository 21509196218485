<template>
  <section class="campaign-setup-pixels-event-filter">
    <label class="campaign-setup-pixels-event-filter__title">Fire Conditionally</label>
    <el-input
      v-scrollable:xy
      :value="pixel.subid"
      :disabled="pixel.subvar === null"
      :placeholder="placeholder"
      size="mini"
      class="input-with-select campaign-setup-pixels-event-filter__subid-input"
      @input="handleSubidInput">
      <el-select
        slot="prepend"
        v-select-close-on-blur
        :value="pixel.subvar"
        :disabled="pixel.global"
        filterable
        placeholder="Select"
        no-match-text="N/A"
        class="campaign-setup-pixels-event-filter__subvar-select"
        @input="handleSubvarInput">
        <el-option :value="null" label="No" />
        <el-option
          v-for="(label, value) in subvars"
          :key="`option-subvar-${value}`"
          :label="label"
          :value="value"
          class="campaign-setup-pixels-event-filter__subvar-option" />
      </el-select>
    </el-input>
  </section>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';
import { SUBVARS } from '@/constants/pixels';

export default {
  name: 'CampaignSetupPixelsEventFilter',
  props: {
    pixel: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      subvars: SUBVARS
    };
  },
  computed: {
    placeholder() {
      return this.pixel.subvar === null ? '' : 'Must Equal';
    }
  },
  methods: {
    handleSubvarInput(value) {
      const pixel = _cloneDeep(this.pixel);
      pixel.subvar = value;
      if (value === null) {
        pixel.subid = null;
      }
      this.$emit('update-pixel', pixel);
    },
    handleSubidInput(value) {
      const pixel = _cloneDeep(this.pixel);
      pixel.subid = value;
      this.$emit('update-pixel', pixel);
    }
  }
};
</script>
<style lang="scss">
.campaign-setup-pixels-event-filter {
  .el-input {
    .el-input__inner {
      padding-left: $--clb-space-4;
    }
  }

  .el-select {
    .el-input {
      min-width: 110px;
    }
  }
}
</style>
