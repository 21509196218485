class PixelCodeGenerator {
  /**
   * Get a list of all the available events of the generator
   * @return {Array}
   */
  getEvents() {};

  /**
   * Check if an event is available for the current gfenerator
   * @param event
   * @return {boolean}
   */
  hasEvent(event) {
    return this.getEvents().includes(event);
  }

  /**
   * Generate pixel code
   * @param {Array.<string>} ids
   * @param {Array.<string>} events
   * @return {string}
   */
  generate(ids, events) {};
}

export default PixelCodeGenerator;
